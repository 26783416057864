/**
 * Site header
 */
.site-header {
  position: relative;
  width: 100%;
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;
  background-color: $background-color;
  border-top: 5px solid $border-color-03;
  border-bottom: 1px solid $border-color-01;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $site-title-color;
  }
}

.site-nav {
  float: right;
  border: none;
  background-color: inherit;

  label[for="nav-trigger"], #nav-trigger {
    display: none;
  }

  .nav-item {
    color: $text-color;
    &:not(:last-child) {
      margin-right: $spacing-unit * 0.5;
    }
  }

  @include media-query($on-medium) {
    position: absolute;
    top: 9px;
    right: $spacing-unit * 0.6;
    background-color: $background-color;
    border: 1px solid $border-color-01;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      cursor: pointer;
    }

    .menu-icon {
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 18px;
      text-align: center;

      &::before {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
      }

      > svg path {
        fill: $border-color-03;
      }
    }

    #nav-trigger {
      ~ label[for=nav-trigger] .menu-icon {
        &::before {
          content: "\f0c9";
          font-size: 1.25em;
        }
      }
      ~ .nav-items {
        clear: both;
        display: none
      }
      &:checked {
      ~ label[for=nav-trigger] .menu-icon {
        &::before {
          content: "\f00d";
          font-size: 1.5em;
        }
      }
        ~ .nav-items {
          display: block;
          padding-bottom: 5px;
          .nav-item {
            margin-left: 20px;
            padding: 5px 10px;
          }
        }
      }
    }

    .nav-item {
      display: block;
      padding: 5px 0;
      color: $text-color;
      line-height: $base-line-height;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $border-color-01;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit * .5;
}

.feed-subscribe .svg-icon {
  padding: 5px 5px 2px 0
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper,
.social-links {
  @include relative-font-size(0.9375);
  color: $brand-color;
}

.footer-col {
  margin-bottom: $spacing-unit * .5;
}

.footer-col-1,
.footer-col-2 {
  width: calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $on-medium) {
  .footer-col-wrapper {
    display: flex
  }

  .footer-col {
    width: calc(100% - (#{$spacing-unit} / 2));
    padding: 0 ($spacing-unit * .5);

    &:first-child {
      padding-right: $spacing-unit * .5;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      padding-left: $spacing-unit * .5;
    }
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $brand-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
  padding-bottom: 18px;
  text-align: center;
  border-bottom: 1px solid $border-color-01;
}

.post-meta {
  .bullet-divider { padding-inline: 15px }
  .meta-label { font-weight: 600 }

  .force-inline {
    display: inline;
    &::before {
      content: "•";
      padding-inline: 5px;
    }
  }
  .post-authors { margin-top: 3px }
}

.post-title,
.post-content h1 {
  margin-bottom: 10px;
  @include relative-font-size(2.625);
  font-weight: $base-font-weight;
  letter-spacing: -1px;
  line-height: 1.15;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4, h5, h6 { margin-top: $spacing-unit }

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
}

.comments-disabled-message {
  text-align: center;
  font-weight: 300;
}

.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    min-width: 45px;
    min-height: 45px;
    text-align: center;
    margin: 5px 10px 5px 0;
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: 10px 12px;
      border: 1px solid $border-color-01;
      &:hover { border-color: $border-color-02 }
    }
  }
}



/**
 * Pagination navbar
 */
.pagination {
  margin-bottom: $spacing-unit;
  @extend .social-media-list;
  li {
    a, div {
      min-width: 41px;
      text-align: center;
      box-sizing: border-box;
    }
    div {
      display: block;
      padding: $spacing-unit * .25;
      border: 1px solid transparent;

      &.pager-edge {
        color: $border-color-01;
        border: 1px dashed;
      }
    }
  }
}



/**
 * Task-lists
 */

.task-list {
  margin-left: 0;
  padding-left: $spacing-unit * 0.6;
}

.task-list-item {
  list-style-type: none;
  &-checkbox {
    position: relative;
    margin-right: $spacing-unit * 0.3;
    margin-left: -$spacing-unit * 0.5;
    appearance: none;
    border: 8px solid $border-color-01;
    vertical-align: text-top;
    z-index: -1;
    &::after {
      position: absolute;
      top: -8px;
      left: -3px;
      width: 4px;
      height: 10px;
      content: "";
      border: solid transparent;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    &:checked::after { border-color: $border-color-03 }
  }
}



/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: calc(50% - (#{$spacing-unit} / 2));
  }
}
